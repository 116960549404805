import { Box, DialogContent, Divider, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Cache } from "aws-amplify";
import { orderBy } from "lodash-es";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import StickyBox from "react-sticky-box";
import store from "store";
import styled from "styled-components";

import { currencySymbolsDictionary, getDictionary } from "data/dictionaries";
import { EPromotingSections, IExperience, IUpcomingBooking } from "data/experiences/types";
import { IExperienceResponse } from "data/ratings/types";
import { EUserType } from "data/users/types";

import useErrors from "hooks/useErrors";
import useNotification from "hooks/useNotification";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import authorizationServices from "services/api/authorization";
import experiencesService from "services/api/experiences";
import { expirationTimeForCache } from "services/aws/auth";

import { useAppSelector } from "store/hooks/reduxToolkitHooks";
import { selectExperienceTravellerBooking } from "store/selectors/experienceTraveller";
import { selectUser } from "store/selectors/user";
import { fetchAuthenticatedUser } from "store/slices/users";

import fillRoute from "utils/routes";

import BookingSimpleBox from "ui/BookingSimpleBox";
import Breadcrumbs from "ui/Breadcrumbs";
import CarouselSwiper from "ui/CarouselSwiper";
import Dialog from "ui/Dialog";
import ChildrenLoader from "ui/loaders/ChildrenLoader";
import RatingStars from "ui/RatingStars";

import colors from "styles/colors";
import { CloseIconBtn, Container, MontserratFontFamily } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

import CovidWarningBox from "../CovidWarningBox";
import VerifiedStatusBox from "../VerifiedStatusBox";
import About from "./components/About";
import AccessibilityRequirements from "./components/AccessibilityRequirements";
import BasicInfo from "./components/BasicInfo";
import BookingForm from "./components/BookingForm";
import BookingPanelForMobile from "./components/BookingPanelForMobile";
import BookingPrivateBox from "./components/BookingPrivateBox";
import CovidInfo from "./components/CovidInfo";
import Description from "./components/Description";
import ImportantInfo from "./components/ImportantInfo";
import KeyAttractions from "./components/KeyAttractions";
import { LastMinuteBox } from "./components/LastMinute";
import MapMode from "./components/MapMode";
import Reviews from "./components/Reviews";

export const TitlePage = styled.h1`
	margin: 0;
	font-family: ${MontserratFontFamily};
	font-size: 2.25rem;
	font-weight: bold;
	line-height: 1.08;
	color: ${colors.mainFontColor};

	${media.tablet`
    font-size: 1rem;
    line-height: 1.25;
  `};
`;

export const TitleSection = styled.h2`
	margin: 30px 0 0;
	font-family: ${MontserratFontFamily};
	font-size: ${dimensions.fontSize.huge};
	font-weight: bold;
	line-height: 1.33;
	color: ${colors.mainFontColor};

	${media.tablet`
    font-size: ${dimensions.fontSize.xlarge};
    line-height: 1.25;
  `};
`;

const ReviewsWrapper = styled.div`
	padding: 30px 0 60px;
	margin: 60px 0 -60px;
	width: 100%;
	background: ${colors.lightGray};
`;

const detailsGallerySettings = {
	lazy: true,
	spaceBetween: 0,
	slidesPerView: 1,
	height: 468,
	navigation: true,
	pagination: { clickable: true },
	breakpoints: {
		768: {
			height: 233,
		},
	},
};

const StyledDialog = styled(Dialog)`
	.MuiDialogContent-root:first-child {
		padding: 2px;
	}
`;

const Traveller = ({
	accessibility,
	activity_type,
	city,
	description,
	description_uniqueness,
	disaster_policies,
	duration_minutes,
	excluded,
	health_restrictions,
	included,
	location,
	media_gallery,
	photo_main,
	physical_difficulty_level,
	relevant_attractions,
	requirements,
	ratings,
	title,
	weather_sensitivities,
	schedule,
	languages,
	country,
	localhost,
	pricing,
	is_verified,
	exp_type,
	created_by,
	id,
	categories,
	number_of_travelers,
	cancellation_policy_hours,
	cutoff_time_hours,
	reviews,
	upcomingBookings,
	internal,
	designed_by,
	designer_details,
}: IExperience & { reviews?: IExperienceResponse[] | null; upcomingBookings?: IUpcomingBooking[] }) => {
	const { t, withRaw } = useTranslation(["common", "seo"]);

	const categoriesDict = getDictionary("CATEGORIES");

	const query = useQuery();
	const lastBooking = query.get("upcomingBooking");

	const theme = useTheme();
	const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const notification = useNotification();
	const { handleAndNotify } = useErrors();

	const userData = useAppSelector(selectUser);
	const bookingData = useAppSelector(selectExperienceTravellerBooking);

	const [galleryImgs, setGalleryImgs] = useState<ReactNode[]>();
	const [previewGalleryImgs, setPreviewGalleryImgs] = useState<string[]>();
	const [showBookingBox, setShowBookingBox] = useState<boolean>(false);
	const [availableBinipool, setAvailableBinipool] = useState<boolean>(false);

	const refGrid = useRef<HTMLElement | null>(null);

	const priceUponRequest = internal?.exposures?.includes(EPromotingSections["PRICE-UPON-REQUEST"]);
	const currency = currencySymbolsDictionary[pricing?.currency || ""];

	useEffect(() => {
		if (media_gallery) {
			const tempListOfImgs = media_gallery.map(v => v.media_url);
			const foundIdx = tempListOfImgs.findIndex(el => el === photo_main);
			tempListOfImgs.splice(foundIdx, 1);
			tempListOfImgs.unshift(photo_main || "");

			const listOfImgs = tempListOfImgs.map((v, i) => (
				<div
					key={i}
					style={{
						backgroundImage: `url(${v})`,
						width: "100%",
						height: "100%",
						backgroundSize: "cover",
						backgroundPosition: "center",
						borderRadius: "4px",
						cursor: "pointer",
					}}
				/>
			));
			setPreviewGalleryImgs(tempListOfImgs);
			setGalleryImgs(listOfImgs);
		}

		// eslint-disable-next-line
	}, [media_gallery]);

	useEffect(() => {
		if (userData?.profile_draft?.cities && userData.profile_draft.cities.indexOf(city) >= 0) {
			// @todo:backend - change it if will be possible to review joining binipools
			setAvailableBinipool(false);
		}
	}, [userData, city]);

	const switchAccountTypeAction = async () => {
		try {
			const authData = await authorizationServices.getSwitchAccountType(EUserType.TRAVELER);

			if (authData?.authorization) {
				await Cache.setItem("Authorization", authData.authorization, { expires: expirationTimeForCache });
			}

			await store.dispatch<any>(fetchAuthenticatedUser({ accountType: EUserType.TRAVELER, reFetchConfig: true }));
		} catch (e) {
			handleAndNotify(e);
		}
	};

	const joinBinipoolAction = async () => {
		try {
			await experiencesService.postJoinBinipool(id, { user_id: userData?.id || "" });

			notification.addSuccess("EXPERIENCE.JOINED_BINIPOOL", true);
		} catch (e) {
			handleAndNotify(e);
		}
	};

	function renderBreadCrumbs() {
		return (
			<Box mt={5}>
				<Breadcrumbs
					withoutHomepage
					list={[
						{ route: fillRoute(paths.SEARCH_COUNTRY, { country }), label: country },
						{ route: fillRoute(paths.SEARCH_COUNTRY_CITY, { country, city }), label: city },
					]}
					customFirstBreadcrumb={{ route: paths.SEARCH, label: t("EXPERIENCE.BACK_TO_EXPERIENCES_BTN") }}
					lastBreadcrumbLabel={title}
				/>
			</Box>
		);
	}

	function renderBookingPanel() {
		return (
			<StickyBox>
				<Box paddingTop="10px" paddingBottom="10px" paddingLeft="40px">
					{userData &&
						[EUserType.JUNIOR_STAFF, EUserType.STAFF, EUserType.ADMIN, EUserType.ROOT, EUserType.LOCALHOST].includes(
							userData.userType,
						) && (
							<>
								<BookingSimpleBox
									description={withRaw("BOOKING_FORM.SWITCH_TRAVELER_DESC")}
									handleClick={() => switchAccountTypeAction()}
									labelBtn={t("BOOKING_FORM.SWITCH_TRAVELER_BTN")}
								/>

								{exp_type === "COHOSTED" && availableBinipool && (
									<BookingSimpleBox
										description={t("BOOKING_FORM.JOIN_BINIPOOL_DESC")}
										handleClick={joinBinipoolAction}
										labelBtn={t("BOOKING_FORM.JOIN_BINIPOOL_BTN")}
									/>
								)}
							</>
						)}

					{(!userData ||
						[EUserType.TRAVELER, EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER].includes(userData?.userType)) && (
						<>
							{!!upcomingBookings?.length && (
								<LastMinuteBox upcomingBookings={upcomingBookings} initialModalOpened={!!lastBooking} />
							)}

							<BookingForm
								title={title}
								languages={languages}
								schedule={schedule}
								pricing={pricing}
								is_verified={is_verified}
								number_of_travelers={number_of_travelers}
								cancellation_policy_hours={cancellation_policy_hours}
								cutoff_time_hours={cutoff_time_hours}
								internal={internal}
								country={country}
							/>

							{!priceUponRequest && !bookingData.superbooking_id && typeof pricing?.private_price === "number" && (
								<Box mt={15}>
									<BookingPrivateBox />
								</Box>
							)}
						</>
					)}
				</Box>
			</StickyBox>
		);
	}

	function renderBookingPanelMobile() {
		return (
			<StyledDialog
				maxWidth="md"
				decorator="withoutLogo"
				showDialog={showBookingBox}
				handleClose={() => setShowBookingBox(false)}
			>
				<DialogContent>
					<CloseIconBtn onClick={() => setShowBookingBox(false)}>
						<Close />
					</CloseIconBtn>

					{userData &&
						[EUserType.JUNIOR_STAFF, EUserType.STAFF, EUserType.ADMIN, EUserType.ROOT, EUserType.LOCALHOST].includes(
							userData.userType,
						) && (
							<>
								<BookingSimpleBox
									description={withRaw("BOOKING_FORM.SWITCH_TRAVELER_DESC")}
									handleClick={() => switchAccountTypeAction()}
									labelBtn={t("BOOKING_FORM.SWITCH_TRAVELER_BTN")}
								/>

								{exp_type === "COHOSTED" && availableBinipool && (
									<BookingSimpleBox
										description={t("BOOKING_FORM.JOIN_BINIPOOL_DESC")}
										handleClick={joinBinipoolAction}
										labelBtn={t("BOOKING_FORM.JOIN_BINIPOOL_BTN")}
									/>
								)}
							</>
						)}

					{(!userData ||
						[EUserType.TRAVELER, EUserType.PARTNER_AGENT, EUserType.PARTNER_MANAGER].includes(userData?.userType)) && (
						<>
							<BookingForm
								title={title}
								languages={languages}
								schedule={schedule}
								pricing={pricing}
								is_verified={is_verified}
								number_of_travelers={number_of_travelers}
								cancellation_policy_hours={cancellation_policy_hours}
								cutoff_time_hours={cutoff_time_hours}
								internal={internal}
								country={country}
							/>

							{!priceUponRequest && !bookingData.superbooking_id && typeof pricing?.private_price === "number" && (
								<Box mt={15}>
									<BookingPrivateBox />
								</Box>
							)}
						</>
					)}
				</DialogContent>
			</StyledDialog>
		);
	}

	function renderContent() {
		return (
			<>
				<Box display="flex" alignItems="center">
					<Box flex={2}>
						<TitlePage>{title}</TitlePage>
					</Box>

					{is_verified && <VerifiedStatusBox currentStatus={t("EXPERIENCE.STATUS_VERIFIED_EXPERIENCE")} />}
				</Box>

				{ratings.count > 0 && (
					<Box mt="5px">
						<RatingStars
							value={ratings.average}
							count={ratings.count}
							detailsRating={ratings.values}
							withoutModal={!ratings.values}
							size={isSmallScreen ? "small" : undefined}
						/>
					</Box>
				)}

				<Box mt="20px" mb="10px">
					<CarouselSwiper
						images={previewGalleryImgs}
						heightSlide="468px"
						slides={galleryImgs || []}
						settings={detailsGallerySettings}
						hidePrevNextBtn={!!galleryImgs && galleryImgs?.length < 2}
					/>
				</Box>

				{!!disaster_policies?.length && (
					<Box mt="15px">
						<CovidWarningBox refGrid={refGrid} />
					</Box>
				)}

				<Box mt={15}>
					<About
						expType={exp_type}
						avatar={localhost?.photo_url}
						description={description}
						firstName={localhost?.first_name || ""}
						uid={exp_type === "UNIQUE" ? created_by : undefined}
						designerId={designed_by}
						designerName={designer_details?.first_name}
						designerAvatar={designer_details?.photo_url}
					/>
				</Box>

				<Box mt={15}>
					<Divider />

					<BasicInfo
						activityType={activity_type}
						city={city}
						durationMin={duration_minutes}
						includingArray={included || []}
					/>
					<Divider />
				</Box>

				{!!description_uniqueness && (
					<Box mt={15}>
						<Description descriptionExperienceUnique={description_uniqueness} />

						<Divider />
					</Box>
				)}

				{!!relevant_attractions?.length && (
					<Box mt={15}>
						<KeyAttractions attractionsArray={relevant_attractions} />

						<Divider />
					</Box>
				)}

				{!!location && (
					<Box mt={15}>
						<MapMode location={location} />

						<Divider />
					</Box>
				)}

				<Box pt={15}>
					<ImportantInfo
						difficultyLevel={physical_difficulty_level}
						excludedArray={excluded}
						healthReastrictionsArray={health_restrictions}
						requirementsArray={requirements}
						weatherSensitive={weather_sensitivities}
					/>

					<Divider />
				</Box>

				<span ref={refGrid} />

				{!!disaster_policies?.length && (
					<Box mt={15}>
						<CovidInfo covidSafetyMeasuresArray={disaster_policies} />

						<Divider />
					</Box>
				)}

				{!!accessibility?.length && (
					<Box mt={15}>
						<AccessibilityRequirements
							accessibilityArray={accessibility}
							translationsK="EXPERIENCE.ACCESSIBILITY_TYPES"
						/>
					</Box>
				)}
			</>
		);
	}

	const showPopup = () => {
		setShowBookingBox(true);
	};

	const mappedCategories = categoriesDict
		.filter(elem => {
			let result = false;

			categories.forEach(category => {
				if (category === elem.value) {
					result = true;
				}
			});

			return result;
		})
		.map(elem => elem.label)
		.join(", ");

	return (
		<>
			<Helmet>
				<title>
					{title} - Experience in {city} | LocalBini
				</title>

				<meta property="og:title" content={`${title} - Experience in ${city} | LocalBini`} />
				<meta name="description" content={`${description} ${t("SEO.EXPERIENCE_PAGE.DESCRIPTION")}`} />
				<meta property="og:description" content={`${description} ${t("SEO.EXPERIENCE_PAGE.DESCRIPTION")}`} />
				<meta name="keywords" content={`Experience, ${city}, ${mappedCategories}`} />
				<meta property="og:image" content={photo_main} />
			</Helmet>

			<Container>
				{renderBreadCrumbs()}
				{isMediumScreen && renderBookingPanelMobile()}
				{isMediumScreen && !showBookingBox && (
					<BookingPanelForMobile
						upcomingBookings={upcomingBookings}
						initialModalOpened={!!lastBooking}
						showPopupCallback={showPopup}
						titleStr={title}
						pricePerPerson={pricing?.filter_price || 0}
						currency={currency}
						internal={internal}
					/>
				)}

				<Grid container direction="row-reverse" spacing={10}>
					<Grid item lg={4}>
						{!isMediumScreen && renderBookingPanel()}
					</Grid>

					<Grid item xs={12} lg={8}>
						{renderContent()}
					</Grid>
				</Grid>
			</Container>

			<ChildrenLoader check={reviews} height={150}>
				{reviews && reviews.length > 0 && (
					<ReviewsWrapper>
						<Container>
							<TitleSection>{t("EXPERIENCE_DETAILS_LOCALS.TITLE_REVIEWS")}</TitleSection>

							<Box mt={20}>
								<Reviews
									gridItemProps={{
										xs: 12,
										md: 4,
										xl: 3,
									}}
									items={orderBy(reviews, "created_on", "desc").map(elem => ({
										created_on: elem.created_on,
										score: elem.score,
										comment: elem.comment_public,
										name: elem.traveler_name,
									}))}
								/>
							</Box>
						</Container>
					</ReviewsWrapper>
				)}
			</ChildrenLoader>
		</>
	);
};

export default Traveller;
