import { Avatar, Box, Button, IconButton } from "@material-ui/core";
import { StarRate } from "@material-ui/icons";
import ReplyIcon from "@material-ui/icons/Reply";
import { MouseEvent, useState } from "react";
import slugify from "slugify";
import styled from "styled-components";

import CoverImg from "assets/images/common/mock_background.svg";

import { currencySymbolsDictionary } from "data/dictionaries";
import { EPromotingSections, IExperienceDTO } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { paths } from "routing/paths";

import { minutesToHM } from "utils/dates";
import fillRoute from "utils/routes";

import SharePopup from "components/Share/components/SharePopup";

import BinipoolAvatar from "ui/BinipoolAvatar";
import RatingStars from "ui/RatingStars";

import colors from "styles/colors";
import { CurrencyWrapper, RobotoFontFamily, StyledLink } from "styles/common";
import dimensions from "styles/dimensions";
import media from "styles/media";

const Wrapper = styled.div<{ blackBackground?: boolean }>`
	margin-bottom: 20px;
	display: flex;
	height: 230px;
	width: 100%;
	box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	transition: box-shadow 0.3s linear;
	position: relative;

	background: ${props => (props.blackBackground ? colors.black : colors.white)};
	${props => props.blackBackground && "border: 1px solid #e53935"};

	* {
		${props => props.blackBackground && `color: ${colors.white} !important`};
	}

	&:hover {
		box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3);
	}

	${media.largeDesktop`
    height: 300px;
  `};

	${media.desktop`
    height: 550px;
    flex-direction: column;
  `};

	${media.tablet`
    height: 100%;
    margin-bottom: 30px;
    &:hover {
      box-shadow: initial;
      transition: initial;
    }
  `};
`;

const Badge = styled.div`
	background-color: #e53935;
	color: ${colors.white};
	font-size: 10px;
	font-weight: bold;
	display: flex;
	align-items: center;
	padding: 4px 14.5px;
	text-transform: uppercase;
	position: absolute;
	top: 0;
	right: 0;
	letter-spacing: 0.23px;

	span {
		padding: 0 8px;
	}

	${media.largeDesktop`
    padding: 4px 1.5px;

    span {
      padding: 0 2px;
    }
  `};

	${media.desktop`
     left: 0;
     right: initial;
  `};
`;

const Image = styled.img`
	width: 100%;
	height: inherit;
	object-fit: cover;
	object-position: center;
	border-radius: 4px 0 0 4px;

	${media.desktop`
    border-radius: 4px 4px 0 0;
  `};
`;

const ImageOptions = styled.div`
	position: absolute;
	top: 15px;
	right: 10px;
	display: flex;
	gap: 10px;
`;

const CenterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 2;
	padding: ${dimensions.spaces.large};
`;

const RightWrapper = styled.div`
	flex: 0.6;
	justify-content: flex-end;
	flex-direction: column;
	display: flex;
	margin: ${dimensions.spaces.large};
	padding-top: 0;
	padding-left: ${dimensions.spaces.large};
	border-left: 1px solid ${colors.dividerColor};
	align-items: flex-start;

	${media.desktop`
    border-left: none;
    border-top: 1px solid ${colors.borderGray};
    flex-direction: row;
    padding-top: ${dimensions.spaces.medium};
    padding-left: 0;
    align-items: center;
    justify-content: space-between;
  `}
`;

const ImageWrapper = styled.div`
	display: flex;
	flex: 1;
	position: relative;
	height: 100%;
	width: 100%;

	${media.desktop`
    height: 216px;
  `}
`;

const Text = styled.span<{
	light?: boolean;
	bold?: boolean;
	marginLeft?: boolean;
	marginTop?: boolean;
	inline?: boolean;
	lightColor?: boolean;
}>`
	font-family: ${RobotoFontFamily};
	font-size: 14px;
	line-height: 1;
	color: ${props => (props.lightColor ? colors.secondaryGray : colors.mainFontColor)};
	font-weight: normal;
	margin-bottom: 0;
	display: block;

	${props => props.light && `font-size: 12px; line-height: 1.73; letter-spacing: 0.34px;`};
	${props => props.bold && `font-weight: bold;`};
	${props => props.marginLeft && `margin-left: 5px;`};
	${props => props.marginTop && `margin-top: 15px;`};
	${props => props.inline && `display: inline;`};
`;

const TextFrom = styled(Text)`
	text-transform: capitalize;
`;

const Title = styled(Text)`
	font-size: 20px;
	font-weight: bold;
	line-height: 0.95;
	letter-spacing: 0.63px;
	text-transform: uppercase;
	margin: 8px 0;

	${media.tablet`
      line-height: 1.15;
      letter-spacing: 0.63px;
  `}
`;

const Categories = styled(Text)`
	font-size: 12px;
	color: ${colors.secondaryGray};
	line-height: 1.67;
	text-transform: uppercase;
`;

const AuthorImage = styled(Avatar)`
	width: 60px;
	height: 60px;
	margin-right: 35px;
`;

const ShareIcon = styled(ReplyIcon)`
	transform: scaleX(-1);
`;

const ActionButton = styled(IconButton)`
	background-color: ${colors.sunShade};
	width: 34px;
	height: 34px;
	color: ${colors.white};

	&:hover {
		background-color: ${colors.mainFontColor};
	}
`;

const Price = styled(Text)`
	font-size: 30px;
	font-weight: bold;
`;

const Row = styled.div<{ marginTop?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	line-height: 1.6;
	${props => props.marginTop && `margin-top: 10px;`};
`;

const Col = styled.div`
	flex-direction: column;
`;

const CenterRightWrapper = styled.div`
	flex-direction: row;
	flex: 2;
	display: flex;

	${media.desktop`
    flex: 1;
    flex-direction: column;
  `};
`;

const BookButton = styled(Button)`
	margin-top: ${dimensions.spaces.medium};
	padding: ${dimensions.spaces.small};
`;

const Item = ({
	id,
	photo_main,
	title,
	duration_minutes,
	localhost,
	pricing,
	description,
	categories,
	city,
	ratings,
	exp_type,
	internal,
}: IExperienceDTO) => {
	const { t } = useTranslation();

	const [showSharePopup, setShowSharePopup] = useState<boolean>(false);

	const currency = currencySymbolsDictionary[pricing?.currency || ""];

	const categoriesString = () => {
		const tempCategories = categories.map(v => t(`EXPERIENCE.CATEGORY.${v}`));
		return tempCategories.join(" | ");
	};

	const shareClickHandle = (ev: MouseEvent<HTMLButtonElement>) => {
		ev.preventDefault();

		setShowSharePopup(true);
	};

	const link = fillRoute(paths.SEARCH_EXPERIENCES, { title: slugify(title.toLowerCase()), id });

	const highlightOfTheWeekExposure = internal?.exposures?.includes(EPromotingSections["HIGHLIGHT-OF-THE-WEEK"]);
	const priceUponRequestExposure = internal?.exposures?.includes(EPromotingSections["PRICE-UPON-REQUEST"]);

	return (
		<>
			{showSharePopup && (
				<SharePopup
					showDialog={showSharePopup}
					setShowDialog={() => setShowSharePopup(false)}
					title={title}
					description={description}
					shareUrl={link}
					addHostName={true}
				/>
			)}

			<StyledLink to={link} $withoutHoverUnderline>
				<Wrapper blackBackground={highlightOfTheWeekExposure}>
					<ImageWrapper>
						<Image className="lazyload" data-src={photo_main || CoverImg} title={title} alt={title} />
						<ImageOptions>
							{/* @todo:backend - add favorite */}
							{/*<ActionButton aria-label="favourite">*/}
							{/*  <FavoriteBorder />*/}
							{/*</ActionButton>*/}
							<ActionButton aria-label="share" onClick={shareClickHandle}>
								<ShareIcon />
							</ActionButton>
						</ImageOptions>
					</ImageWrapper>

					{highlightOfTheWeekExposure && (
						<Badge>
							<StarRate />
							<span>{t("SEARCH.EXPERIENCES.ITEM.BADGE.HIGHLIGHT_OF_WEEK")}</span>
							<StarRate />
						</Badge>
					)}

					<CenterRightWrapper>
						<CenterWrapper>
							<Categories>{categoriesString()}</Categories>

							<Row marginTop>
								{exp_type === "UNIQUE" ? (
									<AuthorImage
										src={localhost?.photo_url || undefined}
										title={`${localhost?.first_name || ""} - Our Local Host`}
										alt={`${localhost?.first_name || ""} - Our Local Host`}
									/>
								) : (
									<Box marginRight="35px">
										<BinipoolAvatar withLabelCohosted={false} withoutBorder />
									</Box>
								)}

								<Col>
									{ratings.count > 0 && (
										<RatingStars
											value={ratings.average}
											count={ratings.count}
											detailsRating={ratings.values}
											size="small"
											withoutModal={!ratings.values}
										/>
									)}

									<Title as="h2">{title}</Title>

									<Text inline lightColor>
										{t("SEARCH.EXPERIENCES.ITEM.ENJOY")}{" "}
										<Text bold inline lightColor>
											{city}
										</Text>{" "}
										{localhost?.first_name && t("SEARCH.EXPERIENCES.ITEM.WITH")}{" "}
										<Text bold inline lightColor>
											{localhost?.first_name}
										</Text>
										<Text marginLeft inline lightColor>
											|
										</Text>
										<Text bold inline marginLeft lightColor>
											{minutesToHM(duration_minutes)}
										</Text>
									</Text>
								</Col>
							</Row>

							<Text as="h3" light marginTop>
								{description?.length > 200 ? description.slice(0, 200) + "..." : description}
							</Text>
						</CenterWrapper>

						<RightWrapper>
							<Col>
								{!priceUponRequestExposure ? (
									<>
										<TextFrom>{t("SEARCH.EXPERIENCES.ITEM.FROM")}</TextFrom>

										<Price>
											<CurrencyWrapper>{currency}</CurrencyWrapper>
											{pricing?.filter_price || 0}
										</Price>

										<Text>{t("SEARCH.EXPERIENCES.ITEM.PER_PERSON")}</Text>
									</>
								) : (
									<Text>{t("SEARCH.EXPERIENCES.ITEM.PRICE_UPON_REQUEST")}</Text>
								)}
							</Col>

							<BookButton variant="contained" color="primary" type="button">
								{t("SEARCH.EXPERIENCES.ITEM.BOOK_THIS_EXPERIENCE")}
							</BookButton>
						</RightWrapper>
					</CenterRightWrapper>
				</Wrapper>
			</StyledLink>
		</>
	);
};

export default Item;
