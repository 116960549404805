import {
	Box,
	Checkbox,
	Divider,
	FormControlLabel,
	InputAdornment,
	List,
	ListItem,
	ListItemText,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import { ChangeEvent, useEffect, useState } from "react";

import Experience from "classes/Experience";

import { TCurrency } from "data/backoffice/financials/types";
import { currencySymbolsDictionary } from "data/dictionaries";
import { IRangeMinMax, Pricing } from "data/experiences/types";
import { ETypeRadioGroup } from "data/experiences/types";

import useTranslation from "hooks/useTranslation";

import { getEarnings } from "utils/prices";

import NumberRadioInput from "components/creation-process/NumberRadioInput";
import NumberSlider from "components/creation-process/NumberSlider";
import TableFixedPrice from "components/creation-process/tables-pricing/TableFixedPrice";
import TableTieredPrice from "components/creation-process/tables-pricing/TableTieredPrice";
import { emptyChartValues } from "components/creation-process/tables-pricing/TableTieredPrice/utils";
import Input from "components/formik/Input";

import BarChartVertical from "ui/BarChartVertical";
import SwitcherYesNo from "ui/forms/SwitcherYesNo";

import colors from "styles/colors";
import { SubTitleSectionV2 } from "styles/common";

import {
	BarChartContainer,
	OrangeBox,
	ShowHideSection,
	StyledDivider,
	StyledKeyboardArrowDown,
	StyledKeyboardArrowUp,
	Text,
	Wrapper,
} from "./shared.styled";

const fixedPaddingTop = "20px";
const fixedPaddingBottom = 20;

interface IPricingEarningPopupProps {
	setFieldValue(fieldName: string, value: unknown): void;
	isEditBox: boolean;
	numberOfTravelers?: IRangeMinMax;
	pricing?: Pricing;
	currency?: TCurrency;
	cancellationPolicyNumber: number;
	preparationTimeNumber: number;
}

const ShowHideArrow = ({ showHideValue }: { showHideValue: boolean }) => {
	if (showHideValue) {
		return <StyledKeyboardArrowUp />;
	} else {
		return <StyledKeyboardArrowDown />;
	}
};

const PricingEarningPopup = ({
	setFieldValue,
	isEditBox,
	numberOfTravelers,
	pricing,
	currency,
	cancellationPolicyNumber,
	preparationTimeNumber,
}: IPricingEarningPopupProps) => {
	const { t } = useTranslation();

	const [showAdultsContent, setShowAdultsContent] = useState<boolean>(false);
	const [showChildrenContent, setShowChildrenContent] = useState<boolean>(false);
	const [showPrivateContent, setShowPrivateContent] = useState<boolean>(false);
	const [showNoticePeriodContent, setShowNoticePeriodContent] = useState<boolean>(false);
	const [showPotentialEarningsContent, setShowPotentialEarningsContent] = useState<boolean>(false);

	const [valueRadioGroup, setValueRadioGroup] = useState<ETypeRadioGroup>(
		pricing?.price_per_person ? ETypeRadioGroup.fixed : ETypeRadioGroup.tiered,
	);
	const [checkedKidsFreeUnder, setCheckedKidsFreeUnder] = useState<boolean>(!!pricing?.kids_free_under);
	const [privatePrice, setPrivatePrice] = useState<number>(pricing?.private_price || 1);
	const [showPrivatePricing, setShowPrivatePricing] = useState<boolean>(!(pricing?.private_price === null));

	useEffect(() => {
		if (isEditBox) {
			setShowAdultsContent(true);
			setShowChildrenContent(true);
			setShowPrivateContent(true);
			setShowNoticePeriodContent(true);
			setShowPotentialEarningsContent(true);
		}
	}, [isEditBox]);

	const handleChangeRadioGroup = (event: ChangeEvent<HTMLInputElement>) => {
		setValueRadioGroup(event.target.value as ETypeRadioGroup);

		if (event.target.value === ETypeRadioGroup.fixed) {
			setFieldValue("pricing.tiered_pricing_details", {});
		} else if (event.target.value === ETypeRadioGroup.tiered) {
			setFieldValue("pricing.price_per_person", null);
		}
	};

	const handleChangeKidsFreeUnder = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedKidsFreeUnder(event.target.checked);

		if (!event.target.checked) {
			setFieldValue("pricing.kids_free_under", null);
		}
	};

	const handleYesNoPrivatePrice = (yesNoValue: boolean) => {
		setShowPrivatePricing(yesNoValue);

		if (yesNoValue) {
			setPrivatePrice(1);
			setFieldValue("pricing.private_price", 1);
		} else {
			setShowPrivatePricing(false);
			setFieldValue("pricing.private_price", null);
		}
	};

	const handleChangePrivatePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = parseInt(event.target.value, 10);

		setPrivatePrice(newValue);
		setFieldValue("pricing.private_price", newValue);
	};

	const currencySymbol = !!currency ? currencySymbolsDictionary[currency] : "";

	return (
		<Wrapper>
			<OrangeBox>{t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.TITLE")}</OrangeBox>

			<Box pt="20px" pl="20px" pr="20px" pb="30px">
				<ShowHideSection onClick={() => setShowAdultsContent(!showAdultsContent)}>
					<SubTitleSectionV2>
						{t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.SUBTITLE_PRICE_FOR_ADULTS")}
					</SubTitleSectionV2>

					<ShowHideArrow showHideValue={showAdultsContent} />
				</ShowHideSection>

				<StyledDivider />

				{showAdultsContent && (
					<Box pb={fixedPaddingBottom}>
						<RadioGroup
							aria-label="price-per-person"
							name="price-per-person"
							value={valueRadioGroup}
							onChange={handleChangeRadioGroup}
						>
							<List key="radio-group-fixed-tiered" dense>
								<ListItem>
									<Radio value={ETypeRadioGroup.fixed} disabled={!isEditBox} />

									<ListItemText
										primary={t("EXPERIENCE.FORM.JSON_PRICES_PER_TRAVELLER.RADIO_GROUP.FIXED_PRICE.TITLE")}
										secondary={t("EXPERIENCE.FORM.NUMBER_RADIO_INPUT.FIXED_PRICE.DESCRIPTION")}
									/>
								</ListItem>

								<ListItem>
									<Radio value={ETypeRadioGroup.tiered} disabled={!isEditBox} />

									<ListItemText
										primary={t("EXPERIENCE.FORM.JSON_PRICES_PER_TRAVELLER.RADIO_GROUP.TIERED_PRICE.TITLE")}
										secondary={t("EXPERIENCE.FORM.NUMBER_RADIO_INPUT.TIERED_PRICE.DESCRIPTION")}
									/>
								</ListItem>
							</List>
						</RadioGroup>

						{valueRadioGroup === ETypeRadioGroup.fixed ? (
							<TableFixedPrice
								onChange={v => setFieldValue("pricing.price_per_person", v)}
								value={pricing?.price_per_person || null}
								currency={currency}
								editMode={isEditBox}
							/>
						) : (
							<TableTieredPrice
								value={pricing?.tiered_pricing_details || {}}
								onChange={v => setFieldValue("pricing.tiered_pricing_details", { ...v })}
								minValue={numberOfTravelers?.min || 1}
								currency={currency}
								maxValue={numberOfTravelers?.max || undefined}
								editMode={isEditBox}
							/>
						)}
					</Box>
				)}

				<ShowHideSection onClick={() => setShowChildrenContent(!showChildrenContent)}>
					<SubTitleSectionV2>
						{t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.SUBTITLE_PRICE_FOR_CHILDREN")}
					</SubTitleSectionV2>

					<ShowHideArrow showHideValue={showChildrenContent} />
				</ShowHideSection>

				<StyledDivider />

				{showChildrenContent && (
					<Box pt={fixedPaddingTop} pb={fixedPaddingBottom}>
						<Box display="flex">
							<FormControlLabel
								control={
									<Checkbox
										checked={checkedKidsFreeUnder}
										onChange={handleChangeKidsFreeUnder}
										name="checkbox-kids_free_under"
										disabled={!isEditBox}
									/>
								}
								label={t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.KIDS_UNDER_LABEL")}
							/>

							<Input
								inputAndWrapperStyles={{ width: "90px" }}
								name="pricing.kids_free_under"
								label={t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.YEARS_OLD_LABEL")}
								type="number"
								readOnly={!isEditBox}
								value={pricing?.kids_free_under ?? 1}
								inputProps={{ min: 1 }}
								disabled={!checkedKidsFreeUnder}
								onChange={(v: ChangeEvent<HTMLInputElement>) =>
									setFieldValue("pricing.kids_free_under", v.target.value)
								}
							/>
						</Box>

						<TableTieredPrice
							field="JSON_PRICES_FOR_CHILDREN"
							onChange={v => setFieldValue("pricing.kids_pricing_details", { ...v })}
							value={pricing?.kids_pricing_details || {}}
							currency={currency}
							minValue={pricing?.kids_free_under ? Number(pricing.kids_free_under) + 1 : 1}
							editMode={isEditBox}
						/>
					</Box>
				)}

				<ShowHideSection onClick={() => setShowPrivateContent(!showPrivateContent)}>
					<SubTitleSectionV2>
						{t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.SUBTITLE_PRIVATE_EXERIENCE")}
					</SubTitleSectionV2>

					<ShowHideArrow showHideValue={showPrivateContent} />
				</ShowHideSection>

				<StyledDivider />

				{showPrivateContent && (
					<Box pt={fixedPaddingTop} pb={fixedPaddingBottom}>
						<SwitcherYesNo onChangeYesNo={handleYesNoPrivatePrice} value={showPrivatePricing} disabled={!isEditBox} />

						{showPrivatePricing && (
							<Box pt={fixedPaddingTop}>
								<Table aria-label="private price table">
									<TableBody>
										<TableRow>
											<TableCell style={{ background: colors.lightGrayWhite }}>
												{t("EXPERIENCE.FORM.PRICING_PRIVATE.PRICE")}
											</TableCell>

											<TableCell align="left" width="195px">
												<TextField
													placeholder={t("EXPERIENCE.FORM.PRICING_PRIVATE.PRICE")}
													value={privatePrice}
													type="number"
													onChange={handleChangePrivatePrice}
													inputProps={{ min: 1 }}
													InputProps={{
														startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
													}}
												/>
											</TableCell>
										</TableRow>

										<TableRow>
											<TableCell style={{ background: colors.lightGrayWhite }}>
												{t("EXPERIENCE.FORM.PRICING_PRIVATE.YOUR_EARNINGS")}
											</TableCell>

											<TableCell align="left">
												<Typography>{`${currencySymbol} ${privatePrice ? getEarnings(privatePrice) : 0}`}</Typography>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Box>
						)}
					</Box>
				)}

				<ShowHideSection onClick={() => setShowNoticePeriodContent(!showNoticePeriodContent)}>
					<SubTitleSectionV2>
						{t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.SUBTITLE_NOTICE_PERIOD")}
					</SubTitleSectionV2>

					<ShowHideArrow showHideValue={showNoticePeriodContent} />
				</ShowHideSection>

				<StyledDivider />

				{showNoticePeriodContent && (
					<Box pt={fixedPaddingTop} pb={fixedPaddingBottom}>
						{isEditBox ? (
							<Box>
								<Text>{t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.CANCELLATION_POLICY_LABEL")}</Text>

								<NumberRadioInput
									onChange={v => setFieldValue("cancellation_policy_hours", parseInt(v, 10))}
									value={cancellationPolicyNumber}
								/>
							</Box>
						) : (
							<Box display="flex" justifyContent="space-between" alignItems="center" pl={5} pr={5} mb={6}>
								<Text>{t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.CANCELLATION_POLICY_LABEL")}</Text>
								<Text>
									{cancellationPolicyNumber} {t("COMMON.HOUR_SHORT")}
								</Text>
							</Box>
						)}

						<Divider />

						{isEditBox ? (
							<Box mt={10}>
								<Text>{t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.PREPARATION_TIME_LABEL")}</Text>

								<NumberSlider onChange={v => setFieldValue("cutoff_time_hours", v)} value={preparationTimeNumber} />
							</Box>
						) : (
							<Box display="flex" justifyContent="space-between" alignItems="center" pl={5} pr={5} mt={6} mb={6}>
								<Text>{t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.PREPARATION_TIME_LABEL")}</Text>

								<Text>
									{preparationTimeNumber} {t("COMMON.HOUR_SHORT")}
								</Text>
							</Box>
						)}

						<Divider />
					</Box>
				)}

				<ShowHideSection onClick={() => setShowPotentialEarningsContent(!showPotentialEarningsContent)}>
					<SubTitleSectionV2>
						{t("EXPERIENCE_DETAILS_LOCALS.PRICING_EARNING_BOX.SUBTITLE_POTENTIAL_EARNINGS")}
					</SubTitleSectionV2>

					<ShowHideArrow showHideValue={showPotentialEarningsContent} />
				</ShowHideSection>

				<StyledDivider />

				{showPotentialEarningsContent && (
					<BarChartContainer
						display="flex"
						alignItems="center"
						pt={fixedPaddingBottom}
						pb={fixedPaddingBottom}
						pl="160px"
					>
						<BarChartVertical
							currency={currency as TCurrency}
							minEarning={typeof pricing?.private_price === "number" ? getEarnings(pricing.private_price) : undefined}
							value={
								(pricing?.price_per_person && Experience.countPricePerPersonFixed(pricing.price_per_person)) ||
								(pricing?.tiered_pricing_details &&
									Experience.countPricePerPersonTiered(pricing.tiered_pricing_details)) ||
								emptyChartValues
							}
						/>
					</BarChartContainer>
				)}
			</Box>
		</Wrapper>
	);
};

export default PricingEarningPopup;
